<template>
  <div>
    <h1>Triple S Ammo Is Currently Under Construction</h1>
    <h2>Please check back later</h2>
  </div>
  <div>
    <img
      src="../assets/triplesammo.jpg"
      alt="triple s ammo logo"
      height="223"
      width="386"
    />
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
</style>
